<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';
import TableSelect from '../components/tableSelect.vue';

formCreate.component('TableSelect', TableSelect);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'quota_management_form',
    };
  },
  mounted() {
  },
  methods: {
    // 设置rule
    setRule(v) {
      const item = v;
      if (item.field === 'companyName') {
        const paramsObj = {
          functionCode: 'select_company',
          data: [],
          selectionList: [],
          idKey: 'orgCode',
          noReset: true,
          paramData: { orgType: 'area', enableStatus: '009' },
        };
        item.props = {
          ...item.props,
          paramsObj,
          placeholder: '分公司',
        };
      }
      if (item.field === 'productName') {
        const paramsObj = {
          functionCode: 'select_product',
          data: [],
          selectionList: [],
          idKey: 'productCode',
          noReset: true,
          paramData: { ext17: '1' },
        };
        item.props = {
          ...item.props,
          paramsObj,
          placeholder: '产品',
        };
      }
      if (item.field === 'seriesName') {
        const paramsObj = {
          functionCode: 'select_series',
          data: [],
          selectionList: [],
          idKey: 'seriesCode',
          noReset: true,
        };
        item.props = {
          ...item.props,
          paramsObj,
          placeholder: '系列',
        };
      }
      return item;
    },
    // 表单渲染完成后调用
    formComplete() {
      const company = this.getRule('companyName');
      company.on.change = (val) => {
        this.setValue({
          companyCode: val.orgCode,
          companyName: val.orgName,
        });
      };
      const product = this.getRule('productName');
      product.on.change = (val) => {
        this.setValue({
          productCode: val.productCode,
          productName: val.productName,
          baseUnit: val.ext3,
          ratio: val.ext35,
        });
      };
      const series = this.getRule('seriesName');
      series.on.change = (val) => {
        this.setValue({
          seriesCode: val.productLevelCode,
          seriesName: val.productLevelName,
        });
      };
      if (this.formConfig.code === 'add') { // 默认为产品
        this.setValue({
          productOrSeries: 'product',
        });
        this.fApi.hidden(true, ['seriesName', 'totalMoney']);
        this.fApi.hidden(false, ['productName', 'totalAmount']);
      }
      const productOrSeries = this.getRule('productOrSeries');
      productOrSeries.on.change = (val) => {
        console.log(val);
        if (val === 'product') {
          this.fApi.hidden(true, ['seriesName', 'totalMoney']);
          this.fApi.hidden(false, ['productName', 'totalAmount']);
          this.setValue({
            seriesCode: null,
            seriesName: null,
            totalMoney: null,
            baseUnit: null,
            ratio: null,
          });
        } else if (val === 'series') {
          this.fApi.hidden(true, ['productName', 'totalAmount']);
          this.fApi.hidden(false, ['seriesName', 'totalMoney']);
          this.setValue({
            productCode: null,
            productName: null,
            totalAmount: null,
            baseUnit: null,
            ratio: null,
          });
        }
      };
      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        if (this.formConfig.row.productOrSeries === 'product') {
          this.fApi.hidden(true, ['seriesName', 'totalMoney']);
          this.fApi.hidden(false, ['productName', 'totalAmount']);
        } else if (this.formConfig.row.productOrSeries === 'series') {
          this.fApi.hidden(true, ['productName', 'totalAmount']);
          this.fApi.hidden(false, ['seriesName', 'totalMoney']);
        }
        this.disabled(true, ['year', 'quarter', 'companyName']);
        this.setValue({ ...this.formConfig.row });
      }
    },
    // 表单提交
    submit() {
      const formData = this.getFormData();
      formData.ratio *= 1;
      const isRato = formData.totalAmount % formData.ratio;
      if (isRato !== 0 && formData.ratio !== 0) {
        this.$message.error('配额总量必须为产品包装规格整数倍');
        return;
      }
      if (formData) {
        let url = '/dms/dms/companyQuota/save';
        const params = { ...formData, ratio: formData.ratio };
        if (this.formConfig.code === 'edit') {
          url = '/dms/dms/companyQuota/update';
          params.quotaCode = this.formConfig.row.quotaCode;
        }
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
