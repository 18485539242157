<script>
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import Form from './form';
import adjustmentForm from './adjustment';
import detailForm from './detail';

export default {
  extends: TablePage,
  components: {
    Form,
    adjustmentForm,
    detailForm,
  },
  data() {
    return {
      saveAllTableColum: [],
      formConfig: {},
      searchFormData: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  async created() {
    // this.searchFormData.productOrSeries = 'product';
  },
  mounted() {
    this.getConfigList('quota_management_list').then((res) => {
      this.saveAllTableColum = res;
    });
  },
  methods: {
    // 判断是否有‘productOrSeries’字段
    hasProductOrSeries() {
      const index = this.searchList.findIndex(
        (item) => item.field === 'productOrSeries',
      );
      if (index > -1) {
        return true;
      }
      return false;
    },
    // 获取列表数据前置函数
    beforeGetList() {
      if (this.hasProductOrSeries()) {
        this.formData.productOrSeries = this.searchFormData.productOrSeries || null;
        if (this.saveAllTableColum.length > 0) {
          this.configs.columns = this.saveAllTableColum.filter((rowData) => {
            if (this.searchFormData.productOrSeries === 'series') {
              if (['productCode', 'productName', 'baseUnit', 'totalAmount', 'usedAmount', 'addAmount', 'changingAmount', 'reduceAmount', 'balanceAmount'].includes(rowData.field)) {
                return false;
              }
            }
            if (this.searchFormData.productOrSeries === 'product') {
              if (['seriesCode', 'seriesName', 'totalMoney', 'usedMoney', 'addMoney', 'changingMoney', 'reduceMoney', 'balanceMoney'].includes(rowData.field)) {
                return false;
              }
            }
            return true;
          });
          this.configs.columns = this.configs.columns.map((v) => this.setColumn(v));
        }
      } else if (this.searchFormData.productOrSeries) {
        delete this.searchFormData.productOrSeries;
      }
      return true;
    },
    /** @override 重写重置按钮 */
    // beforeSearchEvent(val) {
    //   if (val.$event.type === 'reset' && this.hasProductOrSeries()) {
    //     this.searchFormData.productOrSeries = 'product';
    //     this.formData.productOrSeries = this.searchFormData.productOrSeries;
    //   }
    //   return true;
    // },
    setColumn(v) {
      const rowData = v;
      if (this.searchFormData.productOrSeries === 'series') {
        if (['productCode', 'productName', 'baseUnit', 'totalAmount', 'usedAmount', 'addAmount', 'changingAmount', 'reduceAmount', 'balanceAmount'].includes(rowData.field)) { rowData.visible = false; }
        if (['seriesCode', 'seriesName', 'totalMoney', 'usedMoney', 'addMoney', 'changingMoney', 'reduceMoney', 'balanceMoney'].includes(rowData.field)) { rowData.visible = true; }
      }
      if (this.searchFormData.productOrSeries === 'product') {
        if (['seriesCode', 'seriesName', 'totalMoney', 'usedMoney', 'addMoney', 'changingMoney', 'reduceMoney', 'balanceMoney'].includes(rowData.field)) { rowData.visible = false; }
        if (['productCode', 'productName', 'baseUnit', 'totalAmount', 'usedAmount', 'addAmount', 'changingAmount', 'reduceAmount', 'balanceAmount'].includes(rowData.field)) { rowData.visible = true; }
      }
      return rowData;
    },
    modalClick({ val, row }) {
      console.log(val.code);
      if (val.code === 'add' || val.code === 'edit') {
        this.formName = 'Form';
        this.modalConfig.title = '分公司配额';
        this.modalConfig.width = '40%';
        this.formConfig.row = row;
        this.formConfig.code = val.code;
        this.openModal();
      } else if (val.code === 'adjustment') {
        this.formName = 'adjustmentForm';
        this.modalConfig.title = '分公司配额调整';
        this.modalConfig.width = '40%';
        this.formConfig.row = row;
        this.formConfig.code = val.code;
        this.openModal();
      } else if (val.code === 'detail') {
        this.formName = 'detailForm';
        this.modalConfig.title = '明细';
        this.modalConfig.width = '1300px';
        this.propsObjInData.row = row;
        this.openModal();
      } else if (val.code === 'disable') {
        const url = '/dms/dms/companyQuota/disable';
        const params = this.selectRow.map((item) => item.id);
        this.changeStatue(url, params);
      } else if (val.code === 'enable') {
        const url = '/dms/dms/companyQuota/enable';
        const params = this.selectRow.map((item) => item.id);
        this.changeStatue(url, params);
      }
    },
    changeStatue(url, params) {
      if (params.length <= 0) {
        this.$message.error('至少选择一条数据');
        return;
      }
      request.post(url, params).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '操作成功',
          });
          this.onGetList();
        }
      });
    },
  },
};
</script>
